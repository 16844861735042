<template>
  <BCard>
    <BRow class="justify-between flex items-center px-1">
      <div>
        <strong class="text-2xl text-black font-medium">
          Matrik Reliabilitas
        </strong>
      </div>
      <div
        class="flex"
        style="gap:8px;"
      >
        <div class="rounded-[8px] w-[120px] border-[#e2e2e2] border">
          <button
            class="btn d-flex align-items-center justify-content-between px-1 w-full"
            type="button"
            data-toggle="dropdown"
            style="padding: 8px 8px!important"
          >
            {{ dropdownExpedition[activeIndexExpedition].label }}
            <BImg
              class="ml-1"
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-down-1.svg"
            />
          </button>
          <div
            class="dropdown-menu"
            style="z-index: 99;"
          >
            <a
              v-for="(item, index) in dropdownExpedition"
              :key="index"
              class="dropdown-item"
              @click="handleExpedition(index)"
            >{{ item.label }}</a>
          </div>
        </div>
        <MonthlyPicker
          v-model="filterMonthPickup"
          date-format="MMM YYYY"
          :month-labels="locale.monthNames"
          alignment="center"
          class="monthly-metric"
          display="true"
          :max="this.$moment().endOf('month')"
        />
        <div class="rounded-[8px] w-[120px] border-[#e2e2e2] border">
          <button
            class="btn d-flex align-items-center justify-content-between px-1 w-full"
            type="button"
            data-toggle="dropdown"
            style="padding: 8px 8px!important"
          >
            {{ dropdownCod[activeIndexCod].label }}
            <BImg
              class="ml-1"
              src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-down-1.svg"
            />
          </button>
          <div
            class="dropdown-menu"
            style="z-index: 99;"
          >
            <a
              v-for="(item, index) in dropdownCod"
              :key="index"
              class="dropdown-item"
              @click="handleCod(index)"
            >{{ item.label }}</a>
          </div>
        </div>
      </div>
    </BRow>
    <BRow class="wrapper_card">
      <template v-for="(index, key) in dataCard">
        <BOverlay
          :key="key"
          :show="isLoadingCard"
          spinner-variant="primary"
          variant="light"
          blur="0"
          opacity=".5"
          rounded="sm"
        >
          <CardMatric
            :key="key"
            :status="index.status"
            :total="index.total"
            :is-increase="index.is_increase"
            :difference="index.difference"
          />
        </BOverlay>
      </template>
    </BRow>
    <BRow>
      <BOverlay
        :show="loadingChart"
        spinner-variant="primary"
        variant="light"
        blur="0"
        opacity=".5"
        rounded="sm"
        class="w-full"
      >
        <Apexcharts
          ref="myChart"
          height="400"
          :series="dataChart"
          type="area"
          :options="chartOptions"
        />
      </BOverlay>
    </BRow>
    <BRow class="text-[14px] font-semibold ml-1 mt-2 text-[#222222]">
      Table Matrik
    </BRow>
    <BOverlay
      :show="loadingTable"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <BTable
        :items="getFiveDataTablesToggle"
        :fields="fields"
        show-empty
        empty-text="Tidak ada data yang ditampilkan."
        responsive
        class="mt-1"
        outlined
        bordered
      >
        <template #cell(date)="data">
          {{ DATE(data.item.date) }}
        </template>
        <template #cell(total_received)="data">
          <span
            v-if="data.item.diterima !== null"
            class="text-[#34A770]"
          >
            {{ data.item.diterima }}
          </span>
          <span v-else>-</span>
        </template>
        <template #cell(total_return)="data">
          <span
            v-if="data.item.retur !== null"
            class="text-[#FBA63C]"
          >{{
            data.item.retur
          }}</span>
          <span v-else>-</span>
        </template>
        <template #cell(total_return_done)="data">
          <span
            v-if="data.item.retur_done !== null"
            class="text-[#E31A1A]"
          >
            {{ data.item.retur_done }}
          </span>
          <span v-else>-</span>
        </template>
        <template #cell(total_verification)="data">
          <span v-if="data.item.verify !== null">
            {{ data.item.verify }}
          </span>
          <span v-else>-</span>
        </template>
        <template #cell(total_packed)="data">
          <span v-if="data.item.dipacking !== null">
            {{ data.item.dipacking }}
          </span>
          <span v-else>-</span>
        </template>
        <template #cell(total_shipping)="data">
          <span v-if="data.item.dikirim !== null">
            {{ data.item.dikirim }}
          </span>
          <span v-else>-</span>
        </template>
        <template #cell(total_ticket)="data">
          <span v-if="data.item.ticket !== null">
            {{ data.item.ticket }}
          </span>
          <span v-else>-</span>
        </template>
      </BTable>
      <BRow class="flex justify-center">
        <buttton
          class="button-next"
          @click="isCollapse = !isCollapse"
        >
          {{ isCollapse ? 'Selanjutnya' : 'Kecilkan' }}
        </buttton></BRow>
    </BOverlay>
  </BCard>
</template>

<script>
import { BCard, BRow } from 'bootstrap-vue'
import MonthlyPicker from 'vue-monthly-picker'
import { newAxiosIns } from '@/libs/axios'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import {
  DATE, LABELMONTH, MONTH, MONTH_NAME, YEAR,
} from '@/libs/filterDate'
import Apexcharts from 'vue-apexcharts'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import CardMatric from './Card.vue'
import {
  columnConfig,
  dropdownCod,
  dropdownExpedition,
  chartOptions,
} from './config'

export default {
  components: {
    BCard,
    BRow,
    MonthlyPicker,
    Apexcharts,
    CardMatric,
  },
  data() {
    return {
      loadingChart: false,
      isLoadingCard: false,
      dataCard: [],
      items: [],
      dataChart: [],
      isCollapse: true,
      fields: columnConfig,
      loadingTable: true,
      DATE,
      monthlabel: LABELMONTH,
      activeIndexCod: 0,
      activeIndexExpedition: 0,
      locale: {
        format: 'dd/mm/yyyy',
        monthNames: LABELMONTH,
      },
      filterMonthPickup: this.$moment(),
      dropdownExpedition,
      chartOptions,
      dropdownCod,
    }
  },
  computed: {
    maxDatePicker() {
      return this.$moment().endOf('month')
    },
    getFiveDataTablesToggle() {
      if (this.isCollapse) {
        return this.items.slice(0, 5)
      }
      return this.items
    },
  },
  watch: {
    filterMonthPickup: {
      handler() {
        this.getData()
        this.getDataCard()
        this.getDataChart()
      },
    },
    activeIndexCod: {
      handler() {
        this.getData()
        this.getDataCard()
        this.getDataChart()
      },
    },
    activeIndexExpedition: {
      handler() {
        this.getData()
        this.getDataCard()
        this.getDataChart()
      },
    },
  },
  mounted() {
    this.getData()
    this.getDataCard()
    this.getDataChart()
  },
  methods: {
    handleExpedition(index) {
      this.activeIndexExpedition = index
    },

    getDaysInMonth(year, month) {
      return new Date(year, month, 0).getDate()
    },
    handleCod(index) {
      this.activeIndexCod = index
    },
    async getDataChart() {
      this.loadingChart = true
      const params = {
        shipping: this.dropdownExpedition[this.activeIndexExpedition].value,
        type: this.dropdownCod[this.activeIndexCod].value,
        month: MONTH(this.filterMonthPickup),
        year: YEAR(this.filterMonthPickup),
      }

      const url = 'event/api/v1/dashboard/order/matrix-realibility'

      await newAxiosIns
        .get(url, { params })
        .then(res => {
          const { data } = res.data
          this.loadingChart = false
          this.dataChart = [
            {
              name: 'Stuck',
              data: data.map(item => item.total_stuck),
            },
            {
              name: 'Retur',
              data: data.map(item => item.total_retur),
            },
            {
              name: 'Hilang',
              data: data.map(item => item.total_hilang),
            },
          ]

          this.chartOptions = {
            ...this.chartOptions,
            xaxis: {
              ...this.chartOptions.xaxis,
              categories: data.map(e => e.date),
            },
            tooltip: {
              ...this.chartOptions.tooltip,

              custom: ({ dataPointIndex, w }) => {
                let htmlRender = ''
                const arrayData = [...w.globals.series]

                arrayData.forEach((x, idx) => {
                  if (w.globals.collapsedSeriesIndices.indexOf(idx) !== -1) {
                    htmlRender += ''
                  } else {
                    htmlRender += `<div class='flex flex-column'><div class="my-0 mt-1 flex items-center" ">
                 <div style="background-color: ${this.chartOptions.colors[idx]};" class="h-4 w-4 rounded-full"></div> <span class="text-black ml-1"> ${x[dataPointIndex]}
                </div></div>`
                  }
                })
                return `<div class="flex flex-column px-1 py-1 rounded">
              <div>${dataPointIndex + 1} ${MONTH_NAME(
  this.filterMonthPickup,
)} ${YEAR(this.filterMonthPickup)}</div>
              ${htmlRender}
            </div>`
              },
            },
          }
        })
        .catch(err => {
          this.$toast(
            {
              component: ToastificationContentVue,
              props: {
                title: 'Error',
                icon: 'AlertCircleIcon',
                text: err.message,
                variant: 'danger',
              },
            },
            1000,
          )
        })
      this.loadingChart = false
    },
    async getDataCard() {
      this.isLoadingCard = true
      const params = {
        shipping: this.dropdownExpedition[this.activeIndexExpedition].value,
        type: this.dropdownCod[this.activeIndexCod].value,
        month: MONTH(this.filterMonthPickup),
        year: YEAR(this.filterMonthPickup),
      }
      const url = 'event/api/v1/dashboard/order/status-count'

      await newAxiosIns.get(url, { params })
        .then(res => {
          const { data } = res.data
          this.isLoadingCard = false
          this.dataCard = data
        })
        .catch(err => {
          this.$toast(
            {
              component: ToastificationContentVue,
              props: {
                title: 'Error',
                icon: 'AlertCircleIcon',
                text: err.message,
                variant: 'danger',
              },
            },
            1000,
          )
        })
      this.isLoadingCard = false
    },
    async getData() {
      this.loadingTable = true
      const params = {
        shipping: this.dropdownExpedition[this.activeIndexExpedition].value,
        type: this.dropdownCod[this.activeIndexCod].value,
        month: MONTH(this.filterMonthPickup),
        year: YEAR(this.filterMonthPickup),
      }
      const url = 'event/api/v1/dashboard/order/matrix-realibility-table'

      await newAxiosIns
        .get(url, { params })
        .then(res => {
          const { data } = res.data
          this.loadingTable = false
          this.items = data
        })
        .catch(err => {
          this.$toast(
            {
              component: ToastificationContentVue,
              props: {
                title: 'Error',
                icon: 'AlertCircleIcon',
                text: err.message,
                variant: 'danger',
              },
            },
            1000,
          )
        })
      this.loadingTable = false
    },
  },
}
</script>

<style lang="scss">
@import './metrics-realiability.scss'

</style>
