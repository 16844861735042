import {
  DATE,
  END_DATE_OF_MONTH,
  START_DATE_OF_MONTH,
} from '@/libs/filterDate'

export const columnConfig = [
  {
    key: 'date',
    label: 'Tanggal',
    class: 'p-1 align-middle text-center font-medium',
    tdClass: 'text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      color: 'black',
    },
  },
  {
    key: 'total_verification',
    label: 'Verifikasi',
    class: 'p-1 align-middle text-center font-medium',
    tdClass: 'text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      color: 'black',
    },
  },
  {
    key: 'total_packed',
    label: 'Dipacking',
    class: 'p-1 align-middle text-center font-medium',
    tdClass: 'text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      color: 'black',
    },
  },
  {
    key: 'total_shipping',
    label: 'Dikirim',
    class: 'p-1 align-middle text-center font-medium',
    tdClass: 'text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      color: 'black',
    },
  },
  {
    key: 'total_received',
    label: 'Diterima',
    class: 'p-1 align-middle text-center font-medium',
    tdClass: 'text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      color: 'black',
    },
  },
  {
    key: 'total_return',
    label: 'Diputuskan Retur',
    class: 'p-1 align-middle text-center font-medium',
    tdClass: 'text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      color: 'black',
    },
  },
  {
    key: 'total_return_done',
    label: 'Retur Diterima',
    class: 'p-1 align-middle text-center font-medium',
    tdClass: 'text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      color: 'black',
    },
  },
  {
    key: 'total_ticket',
    label: 'Tiket Ekspedisi',
    class: 'p-1 align-middle text-center font-medium',
    tdClass: 'text-black',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      color: 'black',
    },
  },
]

export const dropdownCod = [
  {
    label: 'Semua',
    value: null,
  },
  {
    label: 'COD',
    value: 'cod',
  },
  {
    label: 'non COD',
    value: 'non_cod',
  },
]

export const dropdownExpedition = [
  {
    label: 'Semua',
    value: null,
  },
  {
    label: 'JNE',
    value: 'jne',
  },
  {
    label: 'SiCepat',
    value: 'sicepat',
  },
  {
    label: 'IDExpress',
    value: 'idexpress',
  },
  {
    label: 'Ninja',
    value: 'ninja',
  },
  {
    label: 'J&T',
    value: 'jnt',
  },
  {
    label: 'SAP',
    value: 'sap',
  },
]

export const chartOptions = {
  colors: ['#FBA63C', '#47AEE4', '#E44747'],
  chart: {
    type: 'area',
    stacked: false,
    height: 500,
    width: '100%',
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  markers: {
    size: 0,
  },
  stroke: {
    curve: 'smooth',
  },
  yaxis: {
    tickAmount: 9,
    forceNiceScale: true,
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  xaxis: {
    type: 'datetime',
    tickAmount: 15,
    min: START_DATE_OF_MONTH,
    max: END_DATE_OF_MONTH,
    categories: [],
    labels: {
      formatter: val => DATE(val),
    },
  },
  fill: {
    type: ['gradient', 'gradient', 'gradient'],
    opacity: 1,
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 1,
      opacityTo: 0.1,
      stops: [20, 100, 100, 100],
    },
  },
  noData: {
    text: 'Halo admin, saat ini sedang tidak ada data',
  },
  legend: {
    position: 'bottom',
    horizontalAlign: 'center',
  },
  tooltip: {
    shared: false,
    custom: null,
  },
}
