<template>
  <div
    class="border border-[#e2e2e2] flex flex-column rounded-[8px] p-[12px] gap-[8px]"
  >
    <div class="text-[#222222] text-[14px] capitalize">
      {{ renderStatus }}
    </div>
    <div class="text-[#222222] text-[20px] font-semibold">
      {{ total }}
    </div>
    <div
      class="flex gap-[5px]"
      :class="renderIncrease ? 'text-[#34A770]' : 'text-[#E31A1A]'"
    >
      <img
        v-show="renderIncrease === false"
        src="https://storage.googleapis.com/komerce/assets/icons/CaretUpFill-green.svg"
        alt="decrease"
      >
      <img
        v-show="renderIncrease === true"
        src="https://storage.googleapis.com/komerce//CaretUpFill-red.svg"
        alt="decrease"
      >
      {{ difference }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      default: '',
    },
    total: {
      type: Number,
      default: 0,
    },
    isIncrease: {
      type: Boolean,
      default: true,
    },
    difference: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    renderIncrease() {
      const arrayStatusReverse = ['stuck', 'hilang', 'retur']
      if (arrayStatusReverse.some(list => list === this.status)) {
        return !this.isIncrease
      }
      return this.isIncrease
    },
    renderStatus() {
      if (this.status === 'diterima') {
        return 'selesai'
      }
      return this.status
    },
  },
}
</script>

<style lang="scss" scoped></style>
